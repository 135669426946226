import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { of, throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FormGroup } from '@angular/forms';
import {ProduitType} from "../Types/Produit.type";
import {BienType} from "../Types/Bien.type";


@Injectable({
  providedIn: 'root'
})
export class DataService {

  private HOST = env.HOST;
  private HOST_adresses = env.HOST_adresses;


  constructor(private http: HttpClient, private iab: InAppBrowser) { }
  refs: any;
  fr = {
    firstDayOfWeek: 1,
    dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
    monthNames: ["janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "aout", "septembre", "octobre", "novembre", "decembre"],
    dayNamesShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
    monthNamesShort: ["jan", "fev", "mar", "avr", "mai", "jun", "jul", "aou", "sep", "oct", "nov", "dec"],
    today: "Aujourd'hui",
    clear: 'Effacer'
  }

  reclamationToRenouvellement: any;
  savedBackofficeData: any

  getCalendarfr() {
    return this.fr;
  }

  getYearByAdresse(res): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { ...res };
    return this.http.post<any>(this.HOST_adresses + "/getYear", body, { headers });
  }

  fix2Dates(form:FormGroup,controlName:string) : Promise<any>  {
    if (form.get(controlName).value[0] && form.get(controlName).value[1]) {
      let date_debut = form.get(controlName).value[0];
      let date_fin = form.get(controlName).value[1];

      date_debut = new Date(+date_debut - 1000 * 60 * date_debut.getTimezoneOffset());
      date_fin = new Date(+date_fin - 1000 * 60 * date_fin.getTimezoneOffset());
      return Promise.resolve([date_debut, date_fin]);
    }
  }

  getJsonData(lien): Observable<any> {
    return this.http.get<any>(lien);
  }

  navigateToAddressWithWaze(address: string) {
    const encodedAddress = encodeURIComponent(address);
    const url = `https://waze.com/ul?q=${encodedAddress}`;
    const browser = this.iab.create(url, '_system');
  }

  addAccount(obj: any) {
    this.deleteAccount(obj.utilisateur_ID);//Doit etre en premier
    const accounts = JSON.parse(localStorage.getItem("accounts") || '[]');
    accounts.push(obj);
    localStorage.setItem("accounts", JSON.stringify(accounts));
  }

  changeAccount(utilisateur_ID: string | number) {
    const obj = JSON.parse(localStorage.getItem("accounts") || '[]').find((x: any) => x.utilisateur_ID == utilisateur_ID);
    localStorage.setItem("token", obj.token);
    localStorage.setItem("utilisateur", obj.utilisateur_ID);
    localStorage.setItem("user_type", "1"); //2=agence 1=backoffice
    localStorage.setItem("token_exp", obj.expire);
    localStorage.setItem("mail", obj.mail);
    //Doit rediriger vers /backoffice si necessaire
    this.refs = null;
    window.location.replace('/backoffice');
    //window.location.reload();
  }

  refreshAccount() {
    const oldObj = {
      token: localStorage.getItem("token"),
      utilisateur_ID: localStorage.getItem("utilisateur"),
      expire: localStorage.getItem("token_exp"),
      mail: localStorage.getItem('mail')
    }
    if (oldObj.token && oldObj.utilisateur_ID)
      this.addAccount(oldObj);
  }

  compareArrays(arr1, arr2) {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    if (set1.size !== set2.size) {
      return false;
    }
    for (const item of set1) {
      if (!set2.has(item)) {
        return false;
      }
    }
    return true;
  }

  deleteAccount(utilisateur_ID?: number | string) {
    utilisateur_ID = utilisateur_ID || localStorage.getItem("utilisateur");
    let accounts = JSON.parse(localStorage.getItem("accounts") || '[]');
    accounts = accounts.filter((x: any) => x.utilisateur_ID !== utilisateur_ID);
    localStorage.setItem("accounts", JSON.stringify(accounts));
  }

  disconnect(all = false) {
    localStorage.removeItem('token');
    localStorage.removeItem('utilisateur');
    localStorage.removeItem('mail');
    localStorage.removeItem('token_exp');
    this.refs = null;//Important de vider les refs pour eviter une fuite de donnees si connection a un autre bo
    if (all) localStorage.removeItem('accounts');
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      console.log(error);

      errorMessage = `Nous avons rencontré un problème technique lors de l'enregistrement du rendez-vous.
      Merci de contacter notre secrétariat.
      Nous nous excusons pour la gêne occasionnée.
      Cordialement,`;
    }
    //alert(errorMessage);
    return throwError(errorMessage);
  }


  getRefs() {
    if (this.refs) {
      console.log('depuis le cache');
      return of(this.refs)
    }
    return this.http.get(this.HOST + '/refs/').pipe(map((res: any) => {
      this.refs = res;
      return res;
    }));
  }

  fileDownload(full_url: string) {
    //Necessite le retrait du header authorisation dans l interceptor (fait)
    return this.http.get(full_url, {
      responseType: 'blob'
    });
  }
  getRefsClient() {
    if (this.refs) return of(this.refs);
    return this.http.get(this.HOST + '/refs/client').pipe(map((res) => { this.refs = res; return res; }));
  }

  //fonction pour les non-admin (les connecté a leur compte client n ont pas besoin de transmettre de backoffice_ID)
  getBackofficeData(backoffice_ID: number = null, mini_token: string = "") {
    if (this.savedBackofficeData && backoffice_ID && backoffice_ID==this.savedBackofficeData.backoffice.ID) return of(this.savedBackofficeData);

    return this.http.post(this.HOST + '/general/backoffice/get', { backoffice_ID, mini_token }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(map((res: any) => {
      this.savedBackofficeData = res;
      return res;
    }));
  }



  backofficeData(res, situation: string) {
    return this.http.post(this.HOST + '/general/backoffice/gestion-backoffice', { ...res, situation }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getNoteGestion(categorie_ID, action = "get") {
    return this.http.post(this.HOST + '/general/gestion/note', { categorie_ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  enregistrerNoteGestion(categorie_ID, note, action = "enregistrer") {
    return this.http.post(this.HOST + '/general/gestion/note', { categorie_ID, note, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  SearchPartenaires(recherche: string) {
    return this.http.post(this.HOST + '/recherche/partenaires', { recherche }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  SearchProprietaire(recherche: string) {
    return this.http.post(this.HOST + '/recherche/proprietaire', { recherche }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  SearchMails(recherche: string) {
    return this.http.post(this.HOST + '/recherche/mail', { recherche }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  SearchIfVip(facturation_mail: string) {
    return this.http.post(this.HOST + '/recherche/vip', { facturation_mail }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  SearchIfContactConnue(recherche: string) {
    return this.http.post(this.HOST + '/recherche/contact-connu', { recherche }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  postPrixModificationDiagnosticNew(diagnostics_obligatoires: number[], remise: any[], res, credit_utilise?) {
    return this.http.post(this.HOST + '/diagnostics/prix', { diagnostics_obligatoires, ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  postPrixapplicationReduction(prix: number, remise: any, code_promo, remise_compte_client_ID) {
    return this.http.post(this.HOST + '/diagnostics/remise', { prix, remise, code_promo, remise_compte_client_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  diagnosticObligatoir(ref_bien: number, copro: boolean, superficie: number, ref_construction: number, ref_transaction: number, adresse: any, avec_gaz?) {
    return this.http.post(this.HOST + '/diagnostics/obligatoires', {
      ref_bien, copro, superficie, ref_construction,
      ref_transaction, adresse, avec_gaz
    }, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      })
  }

  gestionDisponibilite(action, res?) {
    return this.http.post(this.HOST + '/rendez-vous/disponibilite', { action, ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  postCreneaux(code_postal: string, duree_rdv: any, semaine_suivante: number,jours_valides, jours_suivants?: number, backoffice_ID?: number) {
    return this.http.post(this.HOST + '/rendez-vous/creneaux', { code_postal, duree_rdv, semaine_suivante,jours_valides, jours_suivants, backoffice_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  postGestionCreneaux(semaine_suivante: number, creneau?) {
    return this.http.post(this.HOST + '/rendez-vous/gestion-creneaux', { semaine_suivante, creneau }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionJourOuvres(action: string, liste?) {
    return this.http.post(this.HOST + '/rendez-vous/gestion-jours-ouvres', { action, liste }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  postRendezVous(res: any, action: string, origin: string) {
    return this.http.post(this.HOST + '/rendez-vous', { ...res, action, origin }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(catchError(this.handleError));
  }

  postErrorLog(res: any) {
    return this.http.post(this.HOST + '/log/httpError', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  modifRendezVous(res: any, ID: number, action: string, origin: string, token?) {
    return this.http.post(this.HOST + '/rendez-vous', { ...res, ID, action, origin, token }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  modifTableauTexte(ID: number, res: any, action: string) {
    return this.http.post(this.HOST + '/rendez-vous', { ID, ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  modifGestionRendezVous(ID: number, res: any, action: string) {
    return this.http.post(this.HOST + '/rendez-vous', { ID, ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  functionSimpleGestionRendezVous(action: string) {
    return this.http.post(this.HOST + '/rendez-vous', { action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  modifTableauDateCreneau(ID: number, jour: any, creneau: any, action: string) {
    return this.http.post(this.HOST + '/rendez-vous', { ID, jour, creneau, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifTableauMenuDeroulant(ID: number, res: any, action: string) {
    return this.http.post(this.HOST + '/rendez-vous/modif_tableau', { ID, res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  supprimerDateRDV(ID: number, action = 'supprimer_date_rdv') {
    return this.http.post(this.HOST + '/rendez-vous', { ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  rendezVousSuiviOuModifie(ID: number, action: string) {
    return this.http.post(this.HOST + '/rendez-vous', { ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  relancerClient(ID: number, action: string) {
    return this.http.post(this.HOST + '/rendez-vous', { ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  relanceGeneralClient(IDs, action: string) {
    return this.http.post(this.HOST + '/rendez-vous', { IDs, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  propositionMissionTechnicien(ID, technicien_IDs, action: string) {
    return this.http.post(this.HOST + '/rendez-vous', { ID, technicien_IDs, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  relanceImpayesByIds(factures_ids, dates, download = false) {
    let response = download ? 'blob' : 'json';
    return this.http.post(this.HOST + '/impayes/relanceImpayes', { factures_ids, dates, download }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    })
  }

  relanceImpayeFacture(facture_ID) {
    return this.http.post(this.HOST + '/impayes/relanceImpaye', { facture_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    })
  }

  telechargeresumeByIds(factures_ids, dates, download = true) {
    return this.http.post(this.HOST + '/impayes/relanceImpayes', { factures_ids, dates, download }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'blob'
    })
  }

  relanceGeneralImpayesByIds(liste_a_relancer, dates) {
    return this.http.post(this.HOST + '/impayes/relanceImpayes', { liste_a_relancer, dates }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getRendezVousAgenda(res) {
    return this.http.post(this.HOST + '/agenda/liste-agenda', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionAgendaFunction(action, res?) {
    return this.http.post(this.HOST + '/agenda/gestion-agenda', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getEvenement(ID) {
    return this.http.get(this.HOST + '/evenement/' + ID);
  }

  deletEvenement(ID) {
    return this.http.delete(this.HOST + '/evenement/' + ID)
  }

  modifEvenement(ID,res) {
    return this.http.put(this.HOST + '/evenement/' + ID, {...res}, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  ajoutEvenement(res) {
    return this.http.post(this.HOST + '/evenement', {...res}, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getRendezVous(res: any, offset?, archive = false) {
    return this.http.post(this.HOST + '/rendez-vous/liste' + (offset ? '/' + offset : ''), { ...res, archive }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }



  getReclamations(res: any, offset?, archive = false) {
    return this.http.post(this.HOST + '/reclamation/listeReclamations' + (offset ? '/' + offset : ''), { ...res, archive }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionMessageReclamation(res, action: string) {
    return this.http.post(this.HOST + '/reclamation/gestion-message-client', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getRdvPourReclamation(ID: number, lien_txt: string, backoffice_ID?) {
    return this.http.post(this.HOST + '/reclamation/affilie', { ID, lien_txt, backoffice_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  envoyerReclamation(res: any, origin, ID?: number) {
    return this.http.post(this.HOST + '/reclamation/ajout', { ...res, ID, origin }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifierReclamation(res: any, ID: number, action: string) {
    return this.http.post(this.HOST + '/reclamation/modification', { ...res, ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifTableauReclamationTexte(ID: number, res: any, action: string) {
    return this.http.post(this.HOST + '/reclamation/modif_tableau', { ID, ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifTableauReclamationMenuDeroulant(ID: number, res: any, action: string) {
    return this.http.post(this.HOST + '/reclamation/modif_tableau', { ID, res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionReclamation(ID: number, action: string) {
    return this.http.post(this.HOST + '/reclamation/modif_tableau', { ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  deleteReclamation(ID: number) {
    return this.http.post(this.HOST + '/reclamation/delete', { ID })
  }

  getReclamationRepTechnicien(ID: number, lien_txt: string) {
    return this.http.post(this.HOST + '/reclamation/page-technicien', { ID, lien_txt }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  nouvellePrestation(res: any, action: string) {
    return this.http.post(this.HOST + '/reclamation/prestation', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(catchError(this.handleError));
  }

  envoyerRepTechnicien(res: any, ID: number) {
    return this.http.post(this.HOST + '/reclamation/reponse-technicien', { ...res, ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getListeChequesRemise(res, action) {
    return this.http.post(this.HOST + '/facturation/gesion-remise-cheque', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionRemiseCheques(res) {
    return this.http.post(this.HOST + '/facturation/gesion-remise-cheque', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  envoyerRenouvellement(res: any, origin: string, ID?: number) {
    return this.http.post(this.HOST + '/renouvellement/ajout', { ...res, origin, ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getErrors(offset?) {
    return this.http.get(this.HOST + '/log/listeErreurs' + (offset ? '/' + offset : ''));
  }



  deleteErrors(ID: number) {
    return this.http.post(this.HOST + '/log/delete', { ID })
  }

  deleteRendezVous(ID: number, action, origin = 'backoffice', token?) {
    return this.http.post(this.HOST + '/rendez-vous', { ID, action, origin, token })
  }

  restoreRendezVous(ID: number, action) {
    return this.http.post(this.HOST + '/rendez-vous', { ID, action })
  }

  deleteCorbeille(action) {
    return this.http.post(this.HOST + '/rendez-vous', { action, origin: 'backoffice' })
  }

  getClients(res, offset?, no_limit = false) {
    return this.http.post(this.HOST + '/clients/listeClients' + (offset ? '/' + offset : ''), { ...res, no_limit }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getImpayes(res, offset?) {
    return this.http.post(this.HOST + '/impayes/listeImpayes' + (offset ? '/' + offset : ''), { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifTableauImpayesTexte(res: any, action: string) {
    return this.http.post(this.HOST + '/impayes/modif_tableau', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  relanceImpayesDOByIds(rdv_IDs: any, action = 'relance_donneur_ordre') {
    return this.http.post(this.HOST + '/impayes/relance_donneur_ordre', { rdv_IDs, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  deleteClient(ID: number) {
    return this.http.post(this.HOST + '/clients/delete', { ID })
  }

  ajoutClient(res: any) {
    return this.http.post(this.HOST + '/clients/ajout', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionClient(res: any, action) {
    return this.http.post(this.HOST + '/clients/gestion-client', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  gestionMailPresentation(res: any, action) {
    return this.http.post(this.HOST + '/clients/gestion-mail-presentation', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modificationClient(res: any, ID: number) {
    return this.http.post(this.HOST + '/clients/modification', { ...res, ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getContactsAgence(res, offset?) {
    return this.http.post(this.HOST + '/acces-client/liste-contacts' + (offset ? '/' + offset : ''), { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionTechniciens(res: any, action) {
    return this.http.post(this.HOST + '/techniciens/gestion-techniciens', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  getTechniciens(res, offset?) {
    return this.http.post(this.HOST + '/techniciens/listeTechniciens' + (offset ? '/' + offset : ''), { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  deleteTechnicien(ID: number) {
    return this.http.post(this.HOST + '/techniciens/delete', { ID })
  }

  ajoutTechnicien(res: any) {
    return this.http.post(this.HOST + '/techniciens/ajout', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modificationTechnicien(res: any, ID: number) {
    return this.http.post(this.HOST + '/techniciens/modification', { ...res, ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getUtilisateurs(recherche, filter_date_rdv, offset?) {
    return this.http.post(this.HOST + '/utilisateur/listeUtilisateurs' + (offset ? '/' + offset : ''), { recherche, filter_date_rdv }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  deleteUtilisateur(ID: number) {
    return this.http.post(this.HOST + '/utilisateur/delete', { ID })
  }

  loginManagement(res: any) {
    return this.http.post(this.HOST + '/utilisateur/login', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true
    })
  }

  refreshToken() {
    return this.http.post(this.HOST + '/utilisateur/refresh', { utilisateur_ID: localStorage.getItem('utilisateur') }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true
    })
  }


  demandeNouveauMdp(mail: string) {
    return this.http.post(this.HOST + '/utilisateur/nouveau-mot-de-passe', { mail }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  ajoutUtilisateur(res: any) {
    return this.http.post(this.HOST + '/utilisateur/ajout', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  modificationUtilisateur(res: any, ID: number) {
    return this.http.post(this.HOST + '/utilisateur/modification', { ...res, ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  utilisateurInfos(bloquer: boolean) {
    let path = bloquer ? '/utilisateur/info-end' : '/utilisateur/info';
    return this.http.get(this.HOST + path);
  }

  gestionUtilisateur(res, action) {
    return this.http.post(this.HOST + '/utilisateur/gestion', { res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  signatureDevis(ID: number, backoffice_ID: number, token: string, action,res = null) {
    return this.http.post(this.HOST + '/complement-dossier/devis-a-signer', { ID, backoffice_ID, token, action, ...res}, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  getRdvPourConplementDossier(ID: number, backoffice_ID: number, lien_txt: string) {
    return this.http.post(this.HOST + '/complement-dossier/a-completer', { ID, backoffice_ID, lien_txt }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }



  envoyerComplementDossier(res: any, ID: number, token: string) {
    return this.http.post(this.HOST + '/complement-dossier/completer-dossier', { ...res, ID, token }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getRdvSatisfactionClient(ID: number, token: string, backoffice_ID: number, action = "get-rdv") {
    return this.http.post(this.HOST + '/complement-dossier/satisfaction-client', { ID, token, backoffice_ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  envoyerSatisfactionClient(res: any, ID: number, action: string) {
    return this.http.post(this.HOST + '/complement-dossier/satisfaction-client', { ...res, ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getRdvPourConfirmation(ID: number, backoffice_ID: number, lien_txt: string) {
    return this.http.post(this.HOST + '/rendez-vous/a-confirmer', { ID, backoffice_ID, lien_txt }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getRdvPourPropositionMission(ID: number, backoffice_ID: number, technicien_ID: number, token: string) {
    return this.http.post(this.HOST + '/rendez-vous/mission-a-accepter', { ID, backoffice_ID, technicien_ID, token }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getRdvPourModifClient(backoffice_ID: number, ID: number, token: string) {
    return this.http.post(this.HOST + '/rendez-vous/pour-modif-client', { backoffice_ID, ID, token }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  confirmationTechnicienRdv(res: any, ID: number, action?) {
    return this.http.post(this.HOST + '/rendez-vous/confirmation', { ...res, ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  EnregistrementTechnicienRdv(res: any, ID: number, action?) {
    return this.http.post(this.HOST + '/rendez-vous/gestion-suivi', { ...res, ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  duplicationRdv(rdv_ID) {
    return this.http.post(this.HOST + '/rendez-vous/duplication', { rdv_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  //Classe pour non admin
  importFile(ID: number, backoffice_ID: number, token: string, uploadData: any) {//ok pour recla et paiement
    return this.http.post(this.HOST + '/upload/import/' + backoffice_ID + '/' + ID + '/' + token, uploadData, {
      reportProgress: true, observe: 'response', responseType: 'json'
    })
  }

  importFileAdmin(ID: number, uploadData: any) {//ok pour recla et paiement
    return this.http.post(this.HOST + '/upload/import/' + ID, uploadData, {
      reportProgress: true, observe: 'response', responseType: 'json'
    })
  }


  getRapportZip(rdv_ID, backoffice_ID: number, token: any) {
    return this.http.post(this.HOST + '/complement-dossier/rapports', { rdv_ID, backoffice_ID, token }, {
      responseType: 'blob'
    })
  }



  getUploadFiles(res: any) {
    return this.http.post(this.HOST + '/upload/get', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifUploadFiles(file_ID: number, result) {
    return this.http.put(this.HOST + '/upload' + file_ID, { ...result }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  deleteUploadFiles(file_ID: number, token = "", backoffice_ID = 0) {
    return this.http.post(this.HOST + '/upload/delete_file', { file_ID, token, backoffice_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  //a supprimer (voir les utilisations)



  chargerInfosPaiement(rendez_vous_ID: number, backoffice_ID: number, hash_token: string) {
    return this.http.post(this.HOST + '/rendez-vous/infos-paiement', { fid: rendez_vous_ID, backoffice_ID: backoffice_ID, h: hash_token });
  }

  envoiPaiementStripe(token: string, data: any, facture_ID: number) {
    return this.http.post(this.HOST + '/paiement/envoi-paiement/stripe', { token, data, facture_ID });
  }

  nouvelleFacture(donneesFacturation) {
    return this.http.post(this.HOST + '/facturation/nouvelle_facture', { ...donneesFacturation }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  genererFacture(rdv_ID) {
    return this.http.post(this.HOST + '/pdf/facture/token/' + rdv_ID, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }
  envoyerAvoir(FactureID, montant,avec_envoi) {
    return this.http.post(this.HOST + '/facturation/envoyer/avoir', { FactureID, montant,avec_envoi }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  getListeAvoirs(FactureID = null, filter_date = null) {
    return this.http.post(this.HOST + '/facturation/liste-avoirs', { FactureID, filter_date }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  facturesGroupees(res: any, action) {
    return this.http.post(this.HOST + '/facturation/factures-groupees', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  getRendezVousClient(res: any, offset?) {
    return this.http.post(this.HOST + '/acces-client/liste-rdv' + (offset ? '/' + offset : ''), { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modificationInfosClient(res: any, mail_id, backoffice_ID) {
    return this.http.post(this.HOST + '/acces-client/modifier-infos', { ...res, mail_id, backoffice_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  inscriptionAgence(res: any) {
    return this.http.post(this.HOST + '/acces-client/inscription', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  notifierParEmailPriseRendezVous(res) {
    return this.http.post(this.HOST + '/rendez-vous/notifier-rdv-en-cours', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  demandeDeRappel(res) {
    return this.http.post(this.HOST + '/rendez-vous/demande-de-rappel', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getClientInfos(backoffice_ID) {
    return this.http.post(this.HOST + '/acces-client/infoclient', { backoffice_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  getListeBackoffices() {
    return this.http.get(this.HOST + '/acces-client/listeBackoffices');
  }

  downloadDiags(id): any {
    return this.http.get(this.HOST + '/acces-client/rapport-diags' + '/' + id + '/', {
      responseType: 'blob'
    });
  }

  downloadFactures(IDs, client_ID): any {
    return this.http.post(this.HOST + '/acces-client/telecharger-facture', { IDs, client_ID }, {
      responseType: 'blob'
    });
  }

  NouveauMdpClient(mail: string) {
    return this.http.post(this.HOST + '/acces-client/newpassword', { mail }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  loginClient(res: any) {
    return this.http.post(this.HOST + '/acces-client/login', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }



  gestionPrix(res: any, cas: string) {
    return this.http.post(this.HOST + '/diagnostics/gestion-prix', { ...res, cas }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listCodePromo(recherche_general, action = "liste") {
    return this.http.post(this.HOST + '/diagnostics/gestion-code-promo', { recherche_general, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  supprimerCode(ID: any, action = "delete") {
    return this.http.post(this.HOST + '/diagnostics/gestion-code-promo', { ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifCodePromo(ID: any, res: any, action = "modif") {
    return this.http.post(this.HOST + '/diagnostics/gestion-code-promo', { ID, ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  ajoutCodePromo(res: any, action = "ajout") {
    return this.http.post(this.HOST + '/diagnostics/gestion-code-promo', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listTag(ref_type_tag_ID: number) {
    return this.http.get(this.HOST + '/tag/' + ref_type_tag_ID)
  }

  supprimerTag(tag_ID) {
    return this.http.delete(this.HOST + '/tag/' + tag_ID)
  }

  appliquerTagUpload(tag_ID: number, file_ID: number, admin: boolean, ref_type_tag_ID: number) {
    return this.http.post(this.HOST + '/tag/set-upload', { tag_ID, file_ID, admin, ref_type_tag_ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifTag(tag_ID: any, res: any) {
    return this.http.post(this.HOST + '/tag/' + tag_ID, { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  ajoutTag(res: any) {
    return this.http.post(this.HOST + '/tag', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listLigneGrille() {
    return this.http.get(this.HOST + '/grille-pack')
  }

  getGrillePackConfig() {
    return this.http.get(this.HOST + '/grille-pack/config');
  }

  getGrillePdf() {
    return this.http.get(this.HOST + '/grille-pack/grille_pdf');
  }

  DeleteLigneGrille(ID) {
    return this.http.delete(this.HOST + '/grille-pack/' + ID)
  }


  modifLigneGrille(ID: any, res: any) {
    return this.http.put(this.HOST + '/grille-pack/' + ID, { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  ajoutLigneGrille(res: any) {
    return this.http.post(this.HOST + '/grille-pack', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listSupplements() {
    return this.http.get(`${this.HOST}/supplements_regionaux`);
  }

  addSupplement(row: any) {
    return this.http.post(`${this.HOST}/supplements_regionaux`, row, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  modifSupplement(id: number, row: any) {
    return this.http.put(`${this.HOST}/supplements_regionaux/${id}`, row, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  deleteSupplement(id: number) {
    return this.http.delete(`${this.HOST}/supplements_regionaux/${id}`);
  }

  mailMissionTechnicien(rdv_id: any) {
    return this.http.post(this.HOST + '/rendez-vous/mail-technicien', { rdv_id }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  sendSMS(res: any) {
    return this.http.post(this.HOST + '/sms/send', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  sendMail(uploadData: any) {//ok pour recla et paiement
    return this.http.post(this.HOST + '/sms/mail', uploadData, {
      reportProgress: true, observe: 'response', responseType: 'json'
    })
  }

  getSmsModeles() {
    return this.http.get(this.HOST + '/sms/modeles')
  }


  ModeleSms(ID: number, cas: string, message: string) {
    return this.http.post(this.HOST + '/sms/gestion', { ID, cas, message }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionModelReclamation(action: string, ID?: number, message?: string) {
    return this.http.post(this.HOST + '/reclamation/gestion-model', { ID, action, message }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getInfoClient(mail_id) {
    return this.http.post(this.HOST + '/clients/infos', { mail_id }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getInfoContact(tel: any, action = "get_info") {
    return this.http.post(this.HOST + '/clients/gestion-contact', { tel, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  supprimerContact(ID: any, action = "delete") {
    return this.http.post(this.HOST + '/clients/gestion-contact', { ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifContact(ID: any, res: any, action = "modif") {
    return this.http.post(this.HOST + '/clients/gestion-contact', { ID, ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listContact(ID: any, recherche_general, offset, action = "liste") {
    return this.http.post(this.HOST + '/clients/gestion-contact', { ID, recherche_general, offset, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  ajoutContact(res: any, action = "ajout") {
    return this.http.post(this.HOST + '/clients/gestion-contact', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  supprimerDemarcheClient(ID: any, action = "delete") {
    return this.http.post(this.HOST + '/clients/gestion-demarchage', { ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifDemarcheClient(ID: any, res: any, action = "modif") {
    return this.http.post(this.HOST + '/clients/gestion-demarchage', { ID, ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listDemarcheClient(compte_client_ID: any, recherche_general, offset, action = "liste") {
    return this.http.post(this.HOST + '/clients/gestion-demarchage', { compte_client_ID, recherche_general, offset, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  ajoutDemarcheClient(res: any, action = "ajout") {
    return this.http.post(this.HOST + '/clients/gestion-demarchage', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  supprimerDemarcheClientImpayés(ID: any, action = "delete") {
    return this.http.post(this.HOST + '/impayes/gestion-demarchage', { ID, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  modifDemarcheClientImpayés(ID: any, res: any, action = "modif") {
    return this.http.post(this.HOST + '/impayes/gestion-demarchage', { ID, ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listDemarcheClientImpayés(mail_ID: any, recherche_general, offset, action = "liste") {
    return this.http.post(this.HOST + '/impayes/gestion-demarchage', { mail_ID, recherche_general, offset, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  ajoutDemarcheClientImpayés(res: any, action = "ajout") {
    return this.http.post(this.HOST + '/impayes/gestion-demarchage', { ...res, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  getContactsDemarchage(res, offset?, no_limit = false) {
    return this.http.post(this.HOST + '/demarchage/listeContacts' + (offset ? '/' + offset : ''), { ...res, no_limit }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  modificationContactDemarchage(res: any, ID: number) {
    return this.http.post(this.HOST + '/demarchage/modification', { ...res, ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  gestionContactDemarchage(ID: number, action) {
    return this.http.post(this.HOST + '/demarchage/gestion', { ID, action })
  }

  ajoutContactDemarchage(res: any) {
    return this.http.post(this.HOST + '/demarchage/ajout', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  //relance demarchage
  modifTableauDate(ID: number, jour: any, action: string) {
    return this.http.post(this.HOST + '/demarchage/gestion', { ID, jour, action }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listStat(filter) {
    return this.http.post(this.HOST + '/stat/graph', { ...filter }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  statTableau(filter) {
    return this.http.post(this.HOST + '/stat/tableau', { ...filter }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  gestionDepartements(res: any, cas) {
    return this.http.post(this.HOST + '/diagnostics/gestion-departements', { ...res, cas }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getFactures(filter, no_limit) {
    return this.http.post(this.HOST + '/facturation/liste_factures', { ...filter, no_limit }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  envoiPaiement(res, facture_ID) {
    return this.http.post(this.HOST + '/paiement/gestion-paiement', { ...res, facture_ID, situation: "nouveau" }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  envoiPaiementNonAdmin(res, facture_ID, backoffice_ID, rdv_ID, token) {
    return this.http.post(this.HOST + '/paiement/gestion-paiement', { ...res, facture_ID, backoffice_ID, rdv_ID, token, situation: "nouveau" }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listePaiements(facture_ID, backoffice_ID?, rdv_ID?, token?) {
    return this.http.post(this.HOST + '/paiement/gestion-paiement', { facture_ID, backoffice_ID, rdv_ID, token, situation: "liste-paiements" }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  deletePaiement(facture_ID: number, paiement_ID: number, backoffice_ID?, rdv_ID?, token?) {
    return this.http.post(this.HOST + '/paiement/gestion-paiement', { facture_ID, paiement_ID, backoffice_ID, rdv_ID, token, situation: 'delete' }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }
  checkPassword(password: string) {
    let msg: any;
    // let strongRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
    let strongRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,}$/;
    let mediumRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    let enoughRegex = /^(?=.{8,})/;

    if (password.length === 0) {
      msg = { valid: false, msg: 'Entrez un mot de passe' };
    } else if (!enoughRegex.test(password)) {
      msg = { valid: false, msg: 'Longueur insuffisante (8 caractères minimum)' };
    } else if (strongRegex.test(password)) {
      msg = { valid: true, msg: 'Fort' };
    } else if (mediumRegex.test(password)) {
      msg = { valid: true, msg: 'Moyen' };
    } else {
      msg = { valid: false, msg: 'Faible' };
    }

    return msg;
  }


  relancesAuto(situation: string, rel: any, delai: number = 0,relance_devis=false) {
    return this.http.post(this.HOST + '/relances/gestion', { situation, params: rel, delai_relance: delai,relance_devis:relance_devis }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  mailHistory() {
    return this.http.get(this.HOST + '/mail/mail-history')
  }

  getPaiements(filtre: any) {
    return this.http.post(this.HOST + '/facturation/liste_paiements', { ...filtre }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  //admin
  chargerInfosPaiementAdmin(admin_facture_ID: number, hash_token: string) {
    return this.http.post(this.HOST + '/gestion/infos-paiement', { admin_facture_ID, hash_token }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  nouvelErp(res: any) {
    return this.http.post(this.HOST + '/adresse/erp', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  renewErp(ID: number) {
    return this.http.put(this.HOST + '/adresse/erp', { ID }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listErp(urlParams: any) {
    return this.http.get(this.HOST + '/adresse/erp?' + new URLSearchParams(urlParams).toString())
  }



  SearchVille(cp: string) {
    return this.http.get(this.HOST + '/adresse/ville/' + cp)
  }

  rechargeCredit(rechargeValue: number) {
    return this.http.post(this.HOST + '/gestion/recharge', { choix_credit: rechargeValue }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getCredits() {
    return this.http.get(this.HOST + '/gestion/recharge')
  }

  getVersion() {
    return this.http.get(this.HOST + '/version')
  }

  achatErp(data: any) {
    return this.http.post(this.HOST + '/acces-client/achat-erp', { ...data }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  adresseComplete(adresse: string,code_postal:string) {
    adresse = encodeURIComponent(adresse);
    return this.http.get('https://api-adresse.data.gouv.fr/search/?q=' + adresse+ (code_postal? '&postcode='+code_postal:''));
  }

  askForDelete() {
    return this.http.delete(this.HOST + '/gestion/delete-account');
  }

  sendSmsAvis(filters: any) {
    return this.http.post(this.HOST + '/sms/avis', { ...filters }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  createOrderViva(res: any) {
    return this.http.post(this.HOST + '/paiement/viva/create-order', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  resetPasswordClient(res: any) {
    return this.http.post(this.HOST + '/acces-client/password-reset', { ...res }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getAdresses(jour: string) {
    return this.http.post(this.HOST + '/adresse/liste', { jour }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  listProduits() {
    return this.http.get(this.HOST + '/produits');
  }

  addProduits(produit:ProduitType) {
    return this.http.post(this.HOST + '/produits', { ...produit }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }


  deleteProduit(ID: number) {
    return this.http.delete(this.HOST + '/produits/'+ID);
  }

  searchProduct(query:string) {
    return this.http.get(this.HOST + '/produits?query='+encodeURIComponent(query));
  }

  updateDossierComplexe(dossiers: BienType[],rdv_ID:number,prix:number) {
    return this.http.put(this.HOST + '/dossier', { dossiers,rdv_ID,prix }, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    })
  }

  getCalendarAcl(technicien_ID:number) {
    return this.http.get(this.HOST + '/techniciens/liste-calendar/'+technicien_ID);
  }
}
